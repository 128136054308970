export const paymentSummaryLabels = {
  subtotalLabel: 'Subtotal',
  taxLabel: 'Tax',
  gratuityLabel: 'Pooled Gratuity',
  tipFromTerminalLabel: 'Tip From Terminal',
  totalLabel: 'Total',
  toBePaidByInvoiceLabel: 'To Be Paid By Invoice'
}

export const basicDetailslabels = {
  suiteLabel: 'SUITE',
  eventLabel: 'EVENT',
  paymentStatusLabel: 'PAYMENT STATUS',
  paymentTypeLabel: 'PAYMENT TYPE',
  menuTypeLabel: 'MENU TYPE',
  submittedByLabel: 'SUBMITTED BY',
  dateSubmittedLabel: 'DATE SUBMITTED',
  totalLabel: 'TOTAL'
}

export const inSuiteOptionsLabel = {
  hostnameLabel: 'HOST NAME',
  itemAdditionsByLabel: 'ITEMS MAY BE ADDED BY',
  inSuitePaymentLabel: 'IN SUITE PAYMENT',
  parInstructionsLabel: 'PAR INSTRUCTIONS',
  signatureRequiredLabel: 'SIGNATURE REQUIRED'
}

export const paymentFieldsToExclude = ['credit_cards', 'invoice']
