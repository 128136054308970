import React from 'react'
import PropTypes from 'prop-types'
import FormHelperText from '@material-ui/core/FormHelperText'
import Tooltip from '@material-ui/core/Tooltip'
import Info from '@material-ui/icons/InfoOutlined'
import CustomFabButton from '../CustomFabButton/CustomFabButton'

const SECTION_LABEL = 'Present bill for signature'
const PRESENT_BILL_FOR_SIGNATURE = `Please select yes
                                      if you would like to add a dedicated gratuity 
                                      for your suite attendant when the final bill 
                                      is presented in suite.`
const VALIDATION_ERROR_MESSAGE = 'You must complete this field.'
const SIGNATURE_OPTIONS = [
  { id: 1, value: 'Yes' },
  { id: 2, value: 'No' }
]

const getToolTip = (classes, title) => {
  return <Tooltip
    classes={{ tooltip: classes.tooltip }}
    title={
      <React.Fragment>
        {title}
      </React.Fragment>
    }
  >
    <Info classes={{ root: classes.infoIconRoot }} />
  </Tooltip>
}

const getSignatureOptions = (classes, signatureRequired, setSignatureReguired) => {
  return SIGNATURE_OPTIONS.map(option => {
    return <CustomFabButton
      key={option.id}
      variant="extended"
      color="default"
      styleClasses={`${classes.genericFabPill} ${signatureRequired === option.id ? classes.activeFabPill : ''}`}
      disableRipple
      disableFocusRipple
      label={option.value}
      value={option.id}
      clickHandler={setSignatureReguired}
    />
  })
}
const SignatureOptions = ({
  classes,
  signatureRequired,
  setSignatureReguired,
  showValidationErrorMessage
}) => {
  return (
    <div className={classes.paymentMethodOptionsContainer}>
      <div className={classes.sectionTitle}>
        {SECTION_LABEL.toUpperCase()}
        {getToolTip(classes, PRESENT_BILL_FOR_SIGNATURE)}
      </div>
      <div className={classes.paymentMethodButtonsContainer}>
        {getSignatureOptions(classes, signatureRequired, setSignatureReguired)}
      </div>
      {
        showValidationErrorMessage &&
        !signatureRequired &&
        <FormHelperText className={classes.inputTextHelper}>
          {VALIDATION_ERROR_MESSAGE}
        </FormHelperText>
      }
    </div>
  )
}

SignatureOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  signatureRequired: PropTypes.number,
  setSignatureReguired: PropTypes.func,
  showValidationErrorMessage: PropTypes.bool
}

SignatureOptions.defaultProps = {
  showValidationErrorMessage: false,
  signatureRequired: null
}

export default SignatureOptions
