import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import DataConainer from '../../elements/DataContainer/DataContainer'
import EventDetailsBar from '../../elements/EventDetailsBar/EventDetailsBar'
import { history } from '../../store/store'
import OrderItemsContainer from '../../elements/OrderItemsContainer/OrderItemsContainer'
import OrderBillDetailsContainer from '../../elements/OrderBillDetailsContainer/OrderBillDetailsContainer'
import MenuItemDetails from '../../Dialogs/MenuItemDetails/MenuItemDetails'
import {
  storeOrderItems,
  setPaymentMethod,
  placeOrder,
  acknowledgeSuccessMessage,
  acknowledgeErrorMessage
} from '../../store/CheckoutFlow/actions'
import { getRecommendations } from '../../store/suiteHolderUsers/actions'
import CustomButton from '../../elements/CustomButton/CustomButton'
import PaymentOptions from '../../elements/PaymentOptions/PaymentOptions'
import InSuiteActionsOptions from '../../elements/InSuiteActionsOptions/InSuiteActionsOptions'
import OrderSuccessMessage from '../../Dialogs/OrderSuccessMessage/OrderSuccessMessage'
import OrderDelayMessage from '../../Dialogs/OrderDelayMessage/OrderDelayMessage'
import Recommendations from '../../elements/Recommendations/Recommendations'
import { currentUserIsAdmin } from '../../store/helpers/userRoleManager'
import { reportAnalytics, reportClientEventsPageLoad } from '../../utils/analytics'
import {
  ANALYTICS_ACTION_LOCATION_BODY,
  ANALYTICS_PAGE_TYPE_CHECKOUT,
  ANALYTICS_ACTION_CART_ADD,
  ANALYTICS_ACTION_CART_RECOMMENDATION_CART_ADD,
  ANALYTICS_ACTION_LOCATION_MODAL,
  ANALYTICS_ACTION_TYPE_RECOMMENDATION_CART_QUICK_ADD,
  ANALYTICS_ACTION_TYPE_RECOMMENDATION_CART_MODAL_ADD,
  ANALYTICS_EVENT_CART_MODAL_ADD } from '../../constants/analytics'

import {
  ADVANCE_PRICE_WINDOW,
  BUSINESS_DAY_NOTE,
  CART_ADD_TYPES,
  ITEM_CANCELLATION_WARNING
} from '../../constants/menuItems'
import { ADD_PRODUCT_DATA, ORDER_CONFIRMATION, ORDER_CONFIRMATION_PAGE, reportGoogleAnalytics } from '../../utils/googleAnalytics'
import isEmpty from '../../store/validation/is-empty'
import styles from './Checkout.css'
import { removeItemFromCart, updateQuantity } from './CheckoutHelpers'
const PAGE_TYPE = 'checkout'
const RECOMMENDATION_TITLE = 'Last Minute Add-ons'
const { QUICK_ADD } = CART_ADD_TYPES
const CREDIT_CARD_PAYMENT_METHOD_ID = 1

const Checkout = ({
  classes,
  eventDetails = {},
  orderItems = [],
  billDetails = {},
  updateOrderItems,
  companyId,
  company,
  eventId,
  menuTypeId,
  suiteId,
  userId,
  placeOrder,
  suiteDisplayName,
  showValidationErrorMessage,
  showOrderSuccessMessage,
  showOrderErrorMessage,
  disablePlaceOrderButton,
  paymentMethod,
  orderId,
  orderWindow,
  hostName,
  itemAdditionsBy,
  signatureRequired,
  orderSuccessResponse: {
    placedOrder = {},
    successMessage = '',
    apiStatus = true
  } = {},
  orderErrorResponse,
  currentUser,
  getRecommendations,
  recommendations = [],
  inSuiteAdditionsCharge
}) => {
  const {
    billSummary = {},
    formattedBillSummary = []
  } = billDetails
  const [showSpinner, setShowSpinner] = useState(false)
  const [showDelayMessage, setShowDelayMessage] = useState(false)
  const [openMenuDetailsDialog, setOpenMenuDetailsDialog] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [orderSubmitted, setOrderSubmitted] = useState(false)
  const [analytics, setAnalytics] = useState(true)
  const [eventName, setEventName] = useState('')
  const [suiteName, setSuiteDisplayName] = useState('')
  const [date, setEventDate] = useState('')
  const [transactionId, setTransactionId] = useState(0)
  const sesCompany = company && company.name === 'SES CUSTOMER'

  const activeRecommendations = recommendations.filter(recommendation => recommendation.isActive)

  const location = useLocation()
  useEffect(() => {
    if (orderItems.length > 0) {
      setEventDate(`${eventDetails.startDate} ${eventDetails.startDateDay} ${eventDetails.startDateMonth}`)
      setEventName(eventDetails.name)
      setSuiteDisplayName(suiteDisplayName)
      setTransactionId(orderId)
      const value = []
      const orderItemsList = orderItems.map(item => {
        value.push(parseFloat(item.totalPrice))
        return {
          item_id: item.menuItemId,
          item_name: item.menu_item_name,
          price: item.totalPrice,
          quantity: item.quantity
        }
      })
      reportGoogleAnalytics('checkout_page_load', {
        ecommerce: {
          currency: 'USD',
          value: value.reduce((a, b) => a + b, 0),
          items: orderItemsList
        }
      })
    }
  }, [location])

  useEffect(() => {
    if (showOrderSuccessMessage || showOrderErrorMessage) {
      setShowSpinner(false)
    }
  }, [showOrderSuccessMessage, showOrderErrorMessage])

  useEffect(() => {
    getRecommendations(PAGE_TYPE)
  }, [])

  useEffect(() => {
    if (currentUser && !currentUserIsAdmin(currentUser) && eventDetails.eventDays) {
      const {
        name,
        eatecId,
        eventDays
      } = eventDetails
      reportClientEventsPageLoad(
        ANALYTICS_PAGE_TYPE_CHECKOUT,
        location.pathname,
        {
          event_name: name,
          event_id: eatecId,
          event_days: eventDays
        }
      )
      reportGoogleAnalytics(
        'checkout_page_view',
        {
          event_name: name,
          event_date: date,
          suite_id: suiteName
        }
      )
    }
  }, [location])

  const activeOrderItems = orderItems.filter(item => item._destroy !== 1)

  const handleQuantityChange = (itemId, quantity) => {
    updateQuantity(itemId, orderItems, quantity, orderWindow, updateOrderItems, orderId)
  }

  const handleRemoveItemFromCart = (itemId, orderItemWindow) => {
    removeItemFromCart(itemId, orderItems, orderItemWindow, updateOrderItems, orderId)
  }

  const navigateBackToOrdering = () => {
    reportGoogleAnalytics('back_to_ordering')
    history.push(`/menu-catalog?companyId=${companyId}&eventId=${eventId}&menuTypeId=${menuTypeId}&suiteId=${suiteId}&userId=${userId}`)
  }

  const debounce = (callback, wait) => {
    let timerId
    return () => {
      clearTimeout(timerId)
      timerId = setTimeout(() => {
        if (!orderSubmitted) {
          callback()
          setTimeout(() => { setShowDelayMessage(true) }, 5000)
        }
      }, wait)
    }
  }

  useEffect(() => {
    const value = []
    if (!isEmpty(orderItems) && apiStatus && showSpinner) {
      orderItems.forEach(item => {
        value.push(parseFloat(item.totalPrice))
        ORDER_CONFIRMATION.ecommerce.items.push({
          item_id: item.menuItemId,
          item_name: item.menu_item_name,
          price: parseFloat(item.unitPrice),
          quantity: item.quantity
        })
      })
      ORDER_CONFIRMATION.ecommerce.transaction_id = transactionId
      ORDER_CONFIRMATION.ecommerce.value = value?.reduce((a, b) => a + b, 0)
      ORDER_CONFIRMATION.ecommerce.suite_name = suiteName
      ORDER_CONFIRMATION.ecommerce.event_date = date
      ORDER_CONFIRMATION.ecommerce.event_name = eventName
      ORDER_CONFIRMATION_PAGE.push({ event_name: eventName, event_date: date, suite_name: suiteName })
    }
  }, [orderItems, showSpinner, apiStatus])

  useEffect(() => {
    if (successMessage && apiStatus && analytics) {
      const orderPageDetails = isEmpty(ORDER_CONFIRMATION_PAGE) ? {} : ORDER_CONFIRMATION_PAGE[0]
      reportGoogleAnalytics('order_confirmation_page_load', ORDER_CONFIRMATION)
      if (orderPageDetails) {
        reportGoogleAnalytics('order_confirmation', orderPageDetails)
      }
      setAnalytics(false)
    }
  }, [successMessage, apiStatus, analytics])

  const submitOrder = () => {
    if (!orderId) {
      reportGoogleAnalytics('place_order')
    } else if (orderId) {
      const value = []
      const orderItemsList = orderItems.map(item => {
        value.push(parseFloat(item.totalPrice))
        return {
          item_id: item.menuItemId,
          item_name: item.menu_item_name,
          item_category: item?.recommended ? 'Recommended' : item.category,
          price: item.totalPrice,
          quantity: item.quantity
        }
      })
      reportGoogleAnalytics('edit_order', {
        ecommerce: {
          transaction_id: transactionId,
          value: value.reduce((a, b) => a + b, 0),
          items: orderItemsList,
          event_date: date,
          event_name: eventName,
          suite_name: suiteName
        }
      })
    }
    setShowSpinner(true)
    setOrderSubmitted(true)
    debounce(placeOrder, 1000)()
  }

  const redirectToHomePage = () => {
    if (currentUserIsAdmin(currentUser)) {
      history.push('/orders')
    } else {
      history.push('/my-events')
    }
  }

  const redirectToOrderDetailsPage = (orderId) => history.push(`/orders/${orderId}`)

  const NO_ADDITION_TO_ORDER = 3

  const getPlaceOrderButtonStatus = () => (!hostName || !paymentMethod || !signatureRequired || !itemAdditionsBy || disablePlaceOrderButton || orderSubmitted || (paymentMethod === CREDIT_CARD_PAYMENT_METHOD_ID && itemAdditionsBy !== NO_ADDITION_TO_ORDER && !inSuiteAdditionsCharge))

  const priceByOrderWindow = (item, effectiveOrderWindow) => {
    return effectiveOrderWindow.toLowerCase() === ADVANCE_PRICE_WINDOW ? item.advancePrice : item.gamedayPrice
  }

  const handleAddToCart = (item, quantity, priceWindow, cartAddType) => {
    const itemPrice = priceByOrderWindow(item, orderWindow)
    const tempCartItem = {
      menuItemId: item.id,
      menu_item_name: item.name,
      unitPrice: itemPrice,
      quantity,
      totalPrice: parseFloat(+itemPrice * quantity).toFixed(2),
      orderWindow: orderWindow,
      recommendedItem: item.recommended
    }
    const existingIndex = orderItems.findIndex(cartItem => cartItem.orderWindow.toLowerCase() === tempCartItem.orderWindow.toLowerCase() && cartItem.menuItemId === tempCartItem.menuItemId && cartItem._destroy !== 1)
    if (existingIndex === -1) {
      orderItems.push(tempCartItem)
    } else {
      const existingItem = orderItems[existingIndex]
      existingItem.quantity += tempCartItem.quantity
      existingItem.totalPrice = parseFloat(+existingItem.unitPrice * existingItem.quantity).toFixed(2)
      orderItems[existingIndex] = existingItem
    }
    reportAnalytics({
      eventType: ANALYTICS_ACTION_CART_ADD,
      detail: {
        actionName: ANALYTICS_ACTION_CART_RECOMMENDATION_CART_ADD,
        actionLocation: cartAddType === QUICK_ADD ? ANALYTICS_ACTION_LOCATION_BODY : ANALYTICS_ACTION_LOCATION_MODAL,
        actionType: cartAddType === QUICK_ADD ? ANALYTICS_ACTION_TYPE_RECOMMENDATION_CART_QUICK_ADD : ANALYTICS_ACTION_TYPE_RECOMMENDATION_CART_MODAL_ADD,
        actionClient: company.name,
        actionMenuEventId: eventDetails.eatecId,
        actionMenuSuite: suiteDisplayName,
        actionItem: item.name,
        actionQty: quantity,
        actionPrice: itemPrice,
        actionForcedPrice: false,
        actionDeal: item.recommended || false
      },
      pageType: ANALYTICS_PAGE_TYPE_CHECKOUT
    })
    getGoogleAnalytics(item, itemPrice, quantity)
    setSelectedItem({})
    setOpenMenuDetailsDialog(false)
    updateOrderItems(orderItems)
  }

  const getGoogleAnalytics = (item, itemPrice, quantity) => {
    ADD_PRODUCT_DATA.ecommerce.items = []
    const category = item?.recommended ? 'Recommended' : item.category
    ADD_PRODUCT_DATA.ecommerce.items.push({
      item_id: item.id,
      item_name: item.name,
      item_category: category,
      addType: ANALYTICS_ACTION_TYPE_RECOMMENDATION_CART_QUICK_ADD,
      company_selection: '',
      suite_selection: suiteDisplayName,
      price: itemPrice,
      quantity: quantity
    })
    ADD_PRODUCT_DATA.ecommerce.value = itemPrice
    reportGoogleAnalytics(ANALYTICS_EVENT_CART_MODAL_ADD, ADD_PRODUCT_DATA)
  }

  const showMenuDetails = (item) => {
    setSelectedItem(item)
    setOpenMenuDetailsDialog(true)
  }

  const onDialogClose = () => {
    setSelectedItem({})
    setOpenMenuDetailsDialog(false)
  }

  const showDialogue = showOrderErrorMessage || showOrderSuccessMessage
  const message = successMessage || orderErrorResponse

  return (
    <DataConainer>
      <div className={classes.eventAndCartContainer}>
        <EventDetailsBar
          eventMonth={eventDetails.startDateMonth}
          eventDate={eventDetails.startDate}
          eventDay={eventDetails.startDateDay}
          eventName={eventDetails.name}
          eventImageUrl={eventDetails.thumbnailUrl}
          eventEatecId={eventDetails.eatecId}
          eventSuiteName={suiteDisplayName}
          clientCutoffDate={eventDetails.clientCutoffDate}
          clientCutoffTime={eventDetails.clientCutoffTime}
          adminCutoffDate={eventDetails.adminCutoffDate}
          adminCutoffTime={eventDetails.adminCutoffTime}
          currentUser={currentUser}
          orderWindow={orderWindow}
          menuTypeId={menuTypeId}
        />
      </div>
      <div className={classes.checkoutDetailsWrapper}>
        <div className={classes.checkoutDetailsTitleContainer}>
          <span className={classes.checkoutTitle}>Checkout</span>
        </div>
        <div className={classes.checkoutDetailsContainer}>
          <div className={classes.orderItemsContainer}>
            <OrderItemsContainer
              currentOrderWindow={orderWindow}
              orderItems={activeOrderItems}
              currentUser={currentUser}
              handleQuantityChange={handleQuantityChange}
              handleRemoveItemFromCart={handleRemoveItemFromCart}
            />
            {
              activeRecommendations && activeRecommendations.length > 0 && <Recommendations
                showMenuDetails={showMenuDetails}
                handleAddToCart={handleAddToCart}
                orderWindow={orderWindow}
                currentUser={currentUser}
                recommendations={activeRecommendations}
                title={RECOMMENDATION_TITLE}
              />
            }
            <OrderBillDetailsContainer billSummary={formattedBillSummary} />
            <CustomButton
              variant={'outlined'}
              disableRipple
              styleClass={classes.backToOrderButtonStyle}
              label={'Back To Ordering'}
              clickHandler={navigateBackToOrdering}
            />
          </div>
          <div className={classes.paymentOptionsContainer}>
            <div className={classes.sectionTitle}>Payment</div>
            <PaymentOptions
              orderTotal={billSummary.total}
              isSesUser={currentUser.single_event_suite_user || sesCompany}
              userId={`${userId}`}
              suiteId={`${suiteId}`}
            />
            <InSuiteActionsOptions
              showValidationErrorMessage={showValidationErrorMessage}
              hideParOptions={currentUser?.single_event_suite_user}
            />
            <CustomButton
              variant={'contained'}
              disableRipple
              styleClass={classes.placeOrderButton}
              label={'Place Order'}
              color={'primary'}
              clickHandler={submitOrder}
              disabled={getPlaceOrderButtonStatus()}
            />
            <Typography className={classes.advancedWarning}>{ITEM_CANCELLATION_WARNING}</Typography>
            <Typography className={classes.businessDayNote}>Note: {BUSINESS_DAY_NOTE}</Typography>
          </div>
        </div>
      </div>
      {
        !successMessage && !showDialogue &&
        <OrderDelayMessage
          class={classes}
          openDialog={showDelayMessage}
          apiStatus={apiStatus}
        />
      }
      <OrderSuccessMessage
        handleGoToHome={redirectToHomePage}
        handleViewOrder={redirectToOrderDetailsPage}
        openDialog={showDialogue}
        orderId={placedOrder.id}
        successMessage={message}
        apiStatus={apiStatus} />
      {
        showSpinner && <div className={classes.spinnerContainer}>
          <CircularProgress disableShrink size={80} className={classes.spinner} />
        </div>
      }
      <MenuItemDetails
        openDialog={openMenuDetailsDialog}
        menuItem={selectedItem}
        handleDialogClose={onDialogClose}
        handleAddToCart={handleAddToCart}
        orderWindow={orderWindow}
        currentUser={currentUser}
      />
    </DataConainer>
  )
}

const mapStateToProps = ({
  checkoutData,
  authReducer
}) => {
  const {
    suite = {}
  } = checkoutData
  return {
    eventDetails: checkoutData.eventDetails,
    orderItems: checkoutData.orderItems,
    billDetails: checkoutData.billDetails,
    companyId: checkoutData.companyId,
    company: checkoutData.company,
    eventId: checkoutData.eventId,
    menuTypeId: checkoutData.menuTypeId,
    suiteId: checkoutData.suiteId,
    userId: checkoutData.userId,
    companiesSuiteId: checkoutData.companiesSuiteId,
    paymentMethod: checkoutData.paymentMethod,
    hostName: checkoutData.hostName,
    signatureRequired: checkoutData.signatureRequired,
    itemAdditionsBy: checkoutData.itemAdditionsBy,
    inSuiteAdditionsCharge: checkoutData.inSuiteAdditionsCharge,
    suiteDisplayName: suite.display_name,
    showValidationErrorMessage: checkoutData.showValidationErrorMessage,
    showOrderSuccessMessage: checkoutData.showOrderSuccessMessage,
    orderErrorResponse: checkoutData.orderErrorResponse,
    showOrderErrorMessage: checkoutData.showOrderErrorMessage,
    disablePlaceOrderButton: checkoutData.disablePlaceOrderButton,
    orderSuccessResponse: checkoutData.orderSuccessResponse,
    orderId: checkoutData.orderId,
    orderWindow: checkoutData.orderWindow,
    currentUser: authReducer.user,
    recommendations: checkoutData.recommendations
  }
}
const mapDispatchToProps = {
  updateOrderItems: storeOrderItems,
  setPaymentMethod,
  placeOrder,
  acknowledgeSuccessMessage,
  acknowledgeErrorMessage,
  getRecommendations
}

Checkout.propTypes = {
  billDetails: PropTypes.object,
  classes: PropTypes.object,
  companyId: PropTypes.number,
  company: PropTypes.object,
  disablePlaceOrderButton: PropTypes.bool,
  currentUser: PropTypes.object,
  eventDetails: PropTypes.object,
  eventId: PropTypes.number,
  getRecommendations: PropTypes.func,
  hostName: PropTypes.string,
  inSuiteAdditionsCharge: PropTypes.number,
  itemAdditionsBy: PropTypes.number,
  menuTypeId: PropTypes.number,
  orderItems: PropTypes.array,
  orderSuccessResponse: PropTypes.object,
  orderErrorResponse: PropTypes.string,
  orderId: PropTypes.number,
  orderWindow: PropTypes.string,
  placeOrder: PropTypes.func,
  paymentMethod: PropTypes.number,
  recommendations: PropTypes.array,
  suiteId: PropTypes.number,
  suiteDisplayName: PropTypes.string,
  showValidationErrorMessage: PropTypes.bool,
  showOrderSuccessMessage: PropTypes.bool,
  showOrderErrorMessage: PropTypes.bool,
  signatureRequired: PropTypes.number,
  updateOrderItems: PropTypes.func,
  userId: PropTypes.number
}

const CheckoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Checkout)

export default withStyles(styles)(CheckoutContainer)
