import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import CustomCheckBox from '../../elements/CustomCheckBox/CustomCheckBox'
import DataConainer from '../../elements/DataContainer/DataContainer'
import { verifyToken } from '../../utils/verifyToken'
import { fetchMenuItemsCatalogBySuite } from '../../store/menuCatalog/actions'
import MenuItemDetails from '../../Dialogs/MenuItemDetails/MenuItemDetails'
import SearchField from '../../elements/SearchField/SearchField'
import EventDetailsBar from '../../elements/EventDetailsBar/EventDetailsBar'
import MenuItemsByCategory from '../../elements/MenuItemsByCategory/MenuItemsByCategory'
import CartIcon from '../../elements/CartIcon/CartIcon'
import CartQuickView from '../../elements/CartQuickView/CartQuickView'
import MenuCategories from '../../elements/MenuCategories/MenuCategories'
import ScrollToTopButton from '../../elements/ScrollToTopButton/ScrollToTopButton'
import { getRecommendations } from '../../store/suiteHolderUsers/actions'
import isEmpty from '../../store/validation/is-empty'
import { ADD_PRODUCT_DATA, CART_PRODUCT_DETAIL, PRODUCT_CLICK_DETAIL, reportGoogleAnalytics } from '../../utils/googleAnalytics'
import {
  getGlutenFreeSelectedOption,
  getVeganSelectedOption,
  filteredCategoryByFoodType,
  filteredCategories
} from '../../utils/menuCategoryFilters'
import {
  ALL_CATEGORIES,
  SCROLL_TOP_OPTIONS,
  CART_ICON_STICKY_POSITION_OFFSET,
  GAMEDAY_PRICE_WINDOW,
  ADVANCE_PRICE_WINDOW,
  ADMIN_ROLES,
  CART_ADD_TYPES,
  ADVANCE_ITEM_DEADLINE_WARNING,
  TAX_AND_GRATUITIES_INFO
} from '../../constants/menuItems'

import {
  ANALYTICS_PAGE_TYPE_MENU_CATALOG,
  ANALYTICS_ACTION_LOCATION_BODY,
  ANALYTICS_ACTION_LOCATION_HEADER,
  ANALYTICS_ACTION_LOCATION_MODAL,
  ANALYTICS_ACTION_CART_SELECTION,
  ANALYTICS_EVENT_BUTTON_CLICK,
  ANALYTICS_ACTION_PROCEED_TO_CHEKOUT,
  ANALYTICS_ACTION_CART_QUICK_ADD,
  ANALYTICS_ACTION_TYPE_CART_QUICK_ADD,
  ANALYTICS_ACTION_TYPE_CART_MODAL_ADD,
  ANALYTICS_ACTION_TYPE_CART_VIEW_QUICK,
  ANALYTICS_ACTION_TYPE_CATALOG_TO_CHECKOUT,
  ANALYTICS_ACTION_CART_ADD,
  ANALYTICS_ACTION_CART_RECOMMENDATION_CART_ADD,
  ANALYTICS_ACTION_TYPE_RECOMMENDATION_CART_QUICK_ADD,
  ANALYTICS_ACTION_TYPE_RECOMMENDATION_CART_MODAL_ADD,
  ANALYTICS_EVENT_CART_MODAL_ADD
} from '../../constants/analytics'

import { reportAnalytics, reportClientEventsPageLoad } from '../../utils/analytics'

import { history } from '../../store/store'
import {
  storeOrderItems,
  getEventDetailsWithOrderWindow,
  getUserPaymentDetails,
  getSuiteDetails,
  storeMenuCatalogPrerequisites
} from '../../store/CheckoutFlow/actions'

import {
  FirstLineCategories,
  SecondLineCategories,
  SearchStyles,
  DietMenuOptions
} from '../../constants/menuCatalog'
import styles from './MenuCatalog.css'

const FOOD_TYPES = [
  {
    name: 'Gluten Free',
    selected: false,
    id: 'gluten_free'
  }, {
    name: 'Vegan',
    selected: false,
    id: 'vegan'
  }
]
const {
  QUICK_ADD,
  MODAL_ADD
} = CART_ADD_TYPES

const PAGE_TYPE = 'menu_catalog'
const MINIMUM_INPUT_LENGTH_FOR_SEARCH = 3

const getItemListByCategory = (itemsByCategory, classes, openMenuItemDetailsDialog, handleAddToCart, dietMenuOptions, orderWindow, currentUser) => {
  const categories = FirstLineCategories.slice(1).concat(SecondLineCategories)
  const isGlutonFreeSelected = getGlutenFreeSelectedOption(dietMenuOptions)
  const isVeganSelected = getVeganSelectedOption(dietMenuOptions)
  const filterdItems = filteredCategories(itemsByCategory, isGlutonFreeSelected, isVeganSelected)
  return categories.map(category => {
    return <MenuItemsByCategory
      key={category}
      category={category}
      items={filterdItems[category]}
      openMenuItemDetailsDialog={openMenuItemDetailsDialog}
      classes={classes}
      orderWindow={orderWindow}
      handleAddToCart={handleAddToCart}
      currentUser={currentUser}
    />
  })
}

const MenuCatalog = ({
  classes,
  currentUser,
  company,
  eventDetails = {},
  existingCartItems = [],
  fetchMenuItemsCatalogBySuite,
  getUserPaymentDetails,
  getSuiteDetails,
  getEventDetailsWithOrderWindow,
  menuItemsCatalogBySuite,
  uniqueCategories,
  orderWindow,
  route,
  storeMenuCatalogPrerequisites,
  suiteDetails,
  suite: {
    display_name: suiteDisplayName
  } = {},
  storeOrderItems,
  userPaymentDetails,
  getRecommendations,
  recommendations = [],
  orderId = '',
  favoriteRecommendations = []
}) => {
  verifyToken()
  const [openDialog, setOpenDialog] = useState(false)
  const [lastAddedItemName, setLastAddedItemName] = useState('')
  const [menuSearchQuery, setMenuSearchQuery] = useState('')
  const [menuItemsByCategory, setMenuItemsByCategory] = useState({})
  const [selectedItem, setSelectedItem] = useState({})
  const [showCartDetails, setShowCartDetails] = useState(false)
  const [showQuickAddNotice, setShowQuickAddNotice] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [dietMenuOptions, setDietMenuOptions] = useState(FOOD_TYPES)

  const suiteName = suiteDisplayName

  const params = route.location.search.replace('?', '').split('&').reduce((r, i) => {
    var temp = i.split('=')
    r[temp[0]] = temp[1]
    return r
  }, {})

  const location = useLocation()

  const { eventId, suiteId, menuTypeId, companyId, userId } = params
  const [stickyCart, setStickyCart] = useState(false)

  const handlePageScroll = () => {
    if (window.pageYOffset > CART_ICON_STICKY_POSITION_OFFSET) {
      setStickyCart(true)
    } else {
      setStickyCart(false)
    }
  }

  useEffect(() => {
    if (!isEmpty(selectedCategories) || (dietMenuOptions[0].selected === true || dietMenuOptions[1].selected === true)) {
      const queryString = getfilters()
      reportGoogleAnalytics('orders', { click_tracking: `menu:filter:${queryString}` })
    }
  }, [selectedCategories, dietMenuOptions])

  const getfilters = () => {
    let filterTypes = []
    const option = dietMenuOptions?.filter(option => option.selected === true)?.map(({ name }) => name)
    filterTypes = [...option, ...selectedCategories]
    return filterTypes.join('+')
  }

  useEffect(() => {
    window.addEventListener('scroll', handlePageScroll)
    return () => window.removeEventListener('scroll', handlePageScroll)
  }, [])

  useEffect(() => {
    if (currentUser && !isCurrentUserAdmin && eventDetails.eventDays) {
      const {
        name,
        eatecId,
        eventDays
      } = eventDetails
      reportClientEventsPageLoad(
        ANALYTICS_PAGE_TYPE_MENU_CATALOG,
        location.pathname,
        {
          event_name: name,
          event_id: eatecId,
          event_days: eventDays
        }
      )
      reportGoogleAnalytics('menu', {
        event_days: eventDays,
        event_name: name,
        suite_id: suiteName
      })
    }
  }, [location, eventDetails, currentUser])

  useEffect(() => {
    getRecommendations(PAGE_TYPE)
  }, [])

  useEffect(() => {
    if (!menuItemsByCategory || (menuItemsByCategory && Object.keys(menuItemsByCategory).length === 0)) {
      if (favoriteRecommendations && menuItemsCatalogBySuite && menuItemsCatalogBySuite.Featured) {
        const featuredItems = menuItemsCatalogBySuite.Featured
          ? menuItemsCatalogBySuite['Featured'].slice(0, 4)
          : []
        menuItemsCatalogBySuite['Featured'] = [...new Set([...featuredItems, ...favoriteRecommendations])]
        setMenuItemsByCategory(menuItemsCatalogBySuite)
      } else {
        setMenuItemsByCategory(menuItemsCatalogBySuite)
      }
    }
  }, [menuItemsCatalogBySuite, favoriteRecommendations])

  useEffect(() => {
    storeMenuCatalogPrerequisites({ eventId: +eventId, suiteId: +suiteId, menuTypeId: +menuTypeId, companyId: +companyId, userId: +userId })
  }, [eventId, suiteId, menuTypeId, companyId, userId, storeMenuCatalogPrerequisites])

  useEffect(() => {
    if (!menuItemsCatalogBySuite) {
      fetchMenuItemsCatalogBySuite({}, suiteId, eventId, menuTypeId, userId)
      getRecommendations(PAGE_TYPE)
    }
  }, [suiteId, eventId, menuTypeId, menuItemsCatalogBySuite, fetchMenuItemsCatalogBySuite, userId, getRecommendations])

  useEffect(() => {
    if (!eventDetails || !orderWindow) {
      getEventDetailsWithOrderWindow(eventId, menuTypeId)
    }
  }, [orderWindow, getEventDetailsWithOrderWindow])

  const isCurrentUserAdmin = currentUser && ADMIN_ROLES.includes(currentUser.role)

  useEffect(() => {
    if (currentUser && isCurrentUserAdmin && !suiteDetails) {
      getSuiteDetails(suiteId)
    }
  }, [getSuiteDetails, suiteDetails, suiteId, currentUser])

  useEffect(() => {
    if (!userPaymentDetails) {
      getUserPaymentDetails(userId, suiteId)
    }
  }, [getUserPaymentDetails, userId, userPaymentDetails])

  function openMenuItemDetailsDialog (item) {
    const category = item.recommended ? 'Recommended' : item.category
    PRODUCT_CLICK_DETAIL.ecommerce.items = []
    const itemPrice = orderWindow.toLowerCase === 'advance' ? item.advancePrice : item.gamedayPrice
    PRODUCT_CLICK_DETAIL.ecommerce.value = parseFloat(itemPrice)
    const itemDetails = {
      item_id: item.id,
      item_name: item.name,
      item_category: category,
      quantity: item.quantity
    }
    PRODUCT_CLICK_DETAIL.ecommerce.items.push(itemDetails)
    reportGoogleAnalytics('select_item', PRODUCT_CLICK_DETAIL)
    setSelectedItem(item)
    setOpenDialog(true)
  }

  const onDialogClose = () => {
    setOpenDialog(false)
  }

  const getEffectiveOrderWindow = (selectedPriceWindow, orderWindow) => {
    return selectedPriceWindow || orderWindow
  }

  const priceByOrderWindow = (item, effectiveOrderWindow) => {
    return effectiveOrderWindow.toLowerCase() === ADVANCE_PRICE_WINDOW ? item.advancePrice : item.gamedayPrice
  }

  const priceWindowOverridedByAdmin = (selectedPriceWindow, orderWindow) => ((orderWindow.toLowerCase() === GAMEDAY_PRICE_WINDOW) && (selectedPriceWindow.toLowerCase() === ADVANCE_PRICE_WINDOW))

  const getAddToCartActionType = (cartAddType, item) => {
    if (cartAddType === QUICK_ADD) {
      return item.recommended ? ANALYTICS_ACTION_TYPE_RECOMMENDATION_CART_QUICK_ADD : ANALYTICS_ACTION_TYPE_CART_QUICK_ADD
    } else {
      return item.recommended ? ANALYTICS_ACTION_TYPE_RECOMMENDATION_CART_MODAL_ADD : ANALYTICS_ACTION_TYPE_CART_MODAL_ADD
    }
  }

  const getGoogleAnalytics = (cartAddType, item, itemPrice, quantity) => {
    const addType = getAddToCartActionType(cartAddType, item)
    if ([ANALYTICS_ACTION_TYPE_RECOMMENDATION_CART_QUICK_ADD, ANALYTICS_ACTION_TYPE_RECOMMENDATION_CART_MODAL_ADD, ANALYTICS_ACTION_TYPE_CART_MODAL_ADD].includes(addType)) {
      const category = item.recommended ? 'Recommended' : item.category
      ADD_PRODUCT_DATA.ecommerce.items = []
      ADD_PRODUCT_DATA.ecommerce.items.push({
        item_id: item.id,
        item_name: item.name,
        item_category: category,
        addType: addType,
        company_selection: company?.name,
        suite_selection: suiteDisplayName,
        price: itemPrice,
        quantity: quantity
      })
      const subtotal = (parseFloat(itemPrice) * quantity)
      ADD_PRODUCT_DATA.ecommerce.value = subtotal
      if (!orderId) {
        reportGoogleAnalytics(ANALYTICS_EVENT_CART_MODAL_ADD, ADD_PRODUCT_DATA)
      }
    }
  }

  const handleAddToCart = (item, quantity, selectedPriceWindow, cartAddType = MODAL_ADD) => {
    const effectiveOrderWindow = getEffectiveOrderWindow(selectedPriceWindow, orderWindow)
    const itemPrice = priceByOrderWindow(item, effectiveOrderWindow)
    const tempCartItem = {
      menuItemId: item.id,
      menu_item_name: item.name,
      unitPrice: itemPrice,
      quantity,
      totalPrice: parseFloat(+itemPrice * quantity).toFixed(2),
      orderWindow: effectiveOrderWindow,
      recommendedItem: item.recommended || false
    }
    if (isCurrentUserAdmin && priceWindowOverridedByAdmin(selectedPriceWindow, orderWindow)) tempCartItem.force_advance_price = true
    const existingIndex = existingCartItems.findIndex(cartItem => cartItem.orderWindow.toLowerCase() === tempCartItem.orderWindow.toLowerCase() && cartItem.menuItemId === tempCartItem.menuItemId && cartItem._destroy !== 1)
    const updatedOrderItems = [...existingCartItems]
    if (existingIndex === -1) {
      updatedOrderItems.push(tempCartItem)
    } else {
      const existingItem = existingCartItems[existingIndex]
      existingItem.quantity += tempCartItem.quantity
      existingItem.totalPrice = parseFloat(+existingItem.unitPrice * existingItem.quantity).toFixed(2)
      updatedOrderItems[existingIndex] = existingItem
    }
    reportAnalytics({
      eventType: ANALYTICS_ACTION_CART_ADD,
      detail: {
        actionName: item.recommended ? ANALYTICS_ACTION_CART_RECOMMENDATION_CART_ADD : ANALYTICS_ACTION_CART_QUICK_ADD,
        actionLocation: cartAddType === QUICK_ADD ? ANALYTICS_ACTION_LOCATION_BODY : ANALYTICS_ACTION_LOCATION_MODAL,
        actionType: getAddToCartActionType(cartAddType, item),
        actionClient: company?.name,
        actionMenuEventId: eventDetails.eatecId,
        actionMenuSuite: suiteDisplayName,
        actionItem: item.name,
        actionQty: quantity,
        actionPrice: itemPrice,
        actionDeal: item.recommended || false,
        actionForcedPrice: tempCartItem.force_advance_price || false
      },
      pageType: ANALYTICS_PAGE_TYPE_MENU_CATALOG
    })
    getGoogleAnalytics(cartAddType, item, itemPrice, quantity)
    storeOrderItems(updatedOrderItems)
    setSelectedItem({})
    setOpenDialog(false)
    setLastAddedItemName(item.name)
    setShowQuickAddNotice(true)
    setTimeout(() => {
      setShowQuickAddNotice(false)
      setLastAddedItemName('')
    }, 2000)
  }

  const quickViewCart = () => {
    getQuickViewGoogleAnalytics(existingCartItems)
    setShowCartDetails(!showCartDetails)
  }
  const getQuickViewGoogleAnalytics = (existingCartItems) => {
    if (!isEmpty(existingCartItems)) {
      const items = []
      CART_PRODUCT_DETAIL.ecommerce.items = []
      const subtotal = []
      existingCartItems.forEach(item => {
        const { menuItemId: id, menu_item_name: name, quantity: qty, unitPrice: price, recommendedItem: rec } = item
        subtotal.push(parseFloat(price * qty))
        items.push({
          item_id: id,
          item_name: name,
          item_category: rec === 'false' ? '' : 'Recommended',
          price: price,
          quantity: qty,
          company_selection: company?.name,
          suite_selection: suiteDisplayName
        })
      })
      CART_PRODUCT_DETAIL.ecommerce.items = items
      CART_PRODUCT_DETAIL.ecommerce.value = subtotal.reduce((a, b) => a + b, 0)
    }
    if (!orderId) {
      reportGoogleAnalytics('view_cart', CART_PRODUCT_DETAIL)
    }
  }
  useEffect(() => {
    if (!showCartDetails) return
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_CART_SELECTION,
        actionLocation: ANALYTICS_ACTION_LOCATION_HEADER,
        actionType: ANALYTICS_ACTION_TYPE_CART_VIEW_QUICK
      },
      pageType: ANALYTICS_PAGE_TYPE_MENU_CATALOG
    })
  }, [showCartDetails])

  const navigateToCheckoutPage = () => {
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_PROCEED_TO_CHEKOUT,
        actionLocation: ANALYTICS_ACTION_LOCATION_MODAL,
        actionType: ANALYTICS_ACTION_TYPE_CATALOG_TO_CHECKOUT
      },
      pageType: ANALYTICS_PAGE_TYPE_MENU_CATALOG
    })
    history.push('/checkout')
  }

  const handleSearchQueryInput = (event) => {
    setMenuSearchQuery(event.target.value)
  }

  const searchMenuItemsByNameorDescription = (query, itemsByCategory, dietMenuOptions) => {
    const regex = new RegExp(`${query.toLowerCase()}`)
    const categories = Object.keys(itemsByCategory)
    const searchResult = {}
    const isGlutonFreeSelected = getGlutenFreeSelectedOption(dietMenuOptions)
    const isVeganSelected = getVeganSelectedOption(dietMenuOptions)
    categories.forEach(category => {
      const items = itemsByCategory[category]
      const filteredItems = items.filter(item => {
        const { name = '', description = '' } = item
        const itemDescription = description || ''
        return name.toLowerCase().match(regex) || itemDescription.toLowerCase().match(regex)
      })
      const clonedFilteredItems = filteredCategoryByFoodType(filteredItems, isGlutonFreeSelected, isVeganSelected)
      if (clonedFilteredItems.length > 0) {
        searchResult[category] = clonedFilteredItems
      }
    })
    reportGoogleAnalytics('search', { search_count_results: searchResult, search_term: query, timestamp: new Date() })
    return searchResult
  }

  useEffect(() => {
    if (menuSearchQuery.length >= MINIMUM_INPUT_LENGTH_FOR_SEARCH) {
      const searchResult = searchMenuItemsByNameorDescription(menuSearchQuery, menuItemsCatalogBySuite, dietMenuOptions)
      setMenuItemsByCategory(searchResult)
      setSelectedCategories(Object.keys(searchResult))
    } else {
      setMenuItemsByCategory(menuItemsCatalogBySuite)
      setSelectedCategories(selectedCategories.filter(item => item === ALL_CATEGORIES).slice())
      const dietMenuOptions = [...DietMenuOptions].map(option => {
        option.selected = false
        return option
      })
      setDietMenuOptions(dietMenuOptions)
    }
  }, [menuSearchQuery])

  const proceedToCheckout = (updatedCartItems) => {
    reportGoogleAnalytics('cart_view_checkout')
    storeOrderItems(updatedCartItems)
    navigateToCheckoutPage()
  }

  const clearSearchResults = () => {
    reportGoogleAnalytics('orders', { click_tracking: `menu:filter:` })
    setMenuItemsByCategory(menuItemsCatalogBySuite)
    setMenuSearchQuery('')
    setSelectedCategories(selectedCategories.filter(item => item === ALL_CATEGORIES).slice())
    const dietMenuOptions = [...DietMenuOptions].map(option => {
      option.selected = false
      return option
    })
    setDietMenuOptions(dietMenuOptions)
  }

  const getCartItemsCount = () => existingCartItems.reduce((count, item) => {
    if (item._destroy !== 1) {
      count += item.quantity
    }
    return count
  }, 0)

  const getActiveItems = () => existingCartItems.filter(item => item._destroy !== 1)

  const handleDietChange = (event) => {
    const options = [...dietMenuOptions]
    const currentCheckboxIndex = options.findIndex(option => option.id === event.currentTarget.name)
    const selectedDiet = options[currentCheckboxIndex]
    options[currentCheckboxIndex].selected = !selectedDiet.selected
    setDietMenuOptions(options)
  }

  return (
    <DataConainer>
      { showQuickAddNotice &&
        <div className={classes.quickAddNotice}>
          {lastAddedItemName} added to cart successfully!
        </div> }
      <div className={classes.eventAndCartContainer}>
        <EventDetailsBar
          eventMonth={eventDetails.startDateMonth}
          eventDate={eventDetails.startDate}
          eventDay={eventDetails.startDateDay}
          eventName={eventDetails.name}
          eventImageUrl={eventDetails.thumbnailUrl}
          eventEatecId={eventDetails.eatecId}
          clientCutoffDate={eventDetails.clientCutoffDate}
          clientCutoffTime={eventDetails.clientCutoffTime}
          adminCutoffDate={eventDetails.adminCutoffDate}
          adminCutoffTime={eventDetails.adminCutoffTime}
          eventSuiteName={suiteDisplayName}
          currentUser={currentUser}
          orderWindow={orderWindow}
          menuTypeId={menuTypeId}
        />
        <div className={stickyCart ? classes.stickyCartContainer : classes.cartQuickViewContainer}>
          <CartIcon viewCart={quickViewCart} lineItemsCount={getCartItemsCount()} />
          { showCartDetails && <CartQuickView
            cartItems={getActiveItems()}
            proceedToCheckout={proceedToCheckout}
            openMenuItemDetailsDialog={openMenuItemDetailsDialog}
            currentUser={currentUser}
            handleAddToCart={handleAddToCart}
          /> }
        </div>
      </div>
      <div className={classes.filterBarContainer}>
        <div className={classes.deadlineText}>{ADVANCE_ITEM_DEADLINE_WARNING}</div>
        <div className={classes.taxAndGratuitiesText}>{TAX_AND_GRATUITIES_INFO}</div>
        <div className={classes.menuFilterContainer}>
          <MenuCategories
            menuSearchQuery={menuSearchQuery}
            menuItemsByCategory={menuItemsByCategory}
            menuItemsCatalogBySuite={menuItemsCatalogBySuite}
            uniqueCategories={uniqueCategories}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            setMenuItemsByCategory={setMenuItemsByCategory}
            clearResults={clearSearchResults}
          />
          <div className={classes.searchFieldContainer}>
            <SearchField query={menuSearchQuery} styles={SearchStyles} handleSearchQueryInput={handleSearchQueryInput} searchOnKeyPress />
            <div className={classes.clearFilters} onClick={clearSearchResults}>Clear Filters</div>
            <div>
              {
                dietMenuOptions.map(option => <CustomCheckBox id={option.id} key={option.id} value={option.id} isChecked={option.selected} label={option.name} handleChange={handleDietChange} />)
              }
            </div>
          </div>
        </div>
      </div>
      <div className={classes.menuItemsWrapper}>
        { menuItemsByCategory && getItemListByCategory(menuItemsByCategory, classes, openMenuItemDetailsDialog, handleAddToCart, dietMenuOptions, orderWindow, currentUser)}
      </div>
      {openDialog && <MenuItemDetails openDialog={openDialog} menuItem={selectedItem} handleDialogClose={onDialogClose} handleAddToCart={handleAddToCart} orderWindow={orderWindow} currentUser={currentUser} />}
      <ScrollToTopButton scrollStepInPx={SCROLL_TOP_OPTIONS.SCROLL_STEP_IN_PX} delayInMs={SCROLL_TOP_OPTIONS.DELAY_IN_MS} />
    </DataConainer>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.authReducer.user,
    eventDetails: state.checkoutData.eventDetails,
    existingCartItems: state.checkoutData.orderItems,
    eventDetailsFetchInProgress: state.checkoutData.eventDetailsFetchInProgress,
    menuItemsCatalogBySuite: state.menuItemsCatalog.menuItemsCatalogBySuite,
    uniqueCategories: state.menuItemsCatalog.uniqueCategories,
    menuItemsCatalogBySuiteInProgress: state.menuItemsCatalog.menuItemsCatalogBySuiteInProgress,
    orderWindow: state.checkoutData.orderWindow,
    suiteDetails: state.checkoutData.suiteDetails,
    userPaymentDetails: state.checkoutData.userPaymentDetails,
    company: state.checkoutData.company,
    suite: state.checkoutData.suite,
    recommendations: state.checkoutData.recommendations,
    orderId: state.checkoutData.orderId,
    favoriteRecommendations: state.checkoutData.favoriteRecommendations
  }
}
const mapDispatchToProps = {
  fetchMenuItemsCatalogBySuite,
  storeMenuCatalogPrerequisites,
  storeOrderItems,
  getEventDetailsWithOrderWindow,
  getUserPaymentDetails,
  getSuiteDetails,
  getRecommendations
}

MenuCatalog.propTypes = {
  classes: PropTypes.object,
  currentUser: PropTypes.object,
  company: PropTypes.object,
  eventDetails: PropTypes.object,
  existingCartItems: PropTypes.array,
  fetchMenuItemsCatalogBySuite: PropTypes.func,
  getEventDetailsWithOrderWindow: PropTypes.func,
  getUserPaymentDetails: PropTypes.func,
  getSuiteDetails: PropTypes.func,
  menuItemsCatalogBySuite: PropTypes.object,
  orderWindow: PropTypes.string,
  route: PropTypes.object,
  storeOrderItems: PropTypes.func,
  suiteDetails: PropTypes.object,
  storeMenuCatalogPrerequisites: PropTypes.func,
  suite: PropTypes.object,
  userPaymentDetails: PropTypes.object,
  uniqueCategories: PropTypes.array,
  recommendations: PropTypes.array,
  favoriteRecommendations: PropTypes.array,
  getRecommendations: PropTypes.func,
  orderId: PropTypes.string
}

const menuCatalogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuCatalog)
export default withStyles(styles)(React.memo(menuCatalogContainer))
