import {
  ANALYTICS_EVENT_PAGE_VIEW,
  BRAND_NAME,
  ANALYTICS_PAGE_TYPE_ORDER_LIST,
  ANALYTICS_EVENT_CLICK_CONTENT,
  ANALYTICS_ACTION_LOCATION_BODY,
  SITE_NAME,
  ANALYTICS_ACTION_LOCATION_INTERAL,
  ANALYTICS_ACTION_TYPE_CTA,
  ANALYTICS_CLICK_CONTENT_CREATION,
  ANALYTICS_CLICK_CREATION_CONTINUE,
  ANALYTICS_EVENT_CAROUSEL_ARROW_CLICK,
  ANALYTICS_PAGE_TYPE_ORDER_CART,
  ANALYTICS_ACTION_CREATE_ORDER,
  ANALYTICS_ACTION_VIEW_CART,
  ANALYTICS_PAGE_TYPE_MENU,
  ANALYTICS_ACTION_TYPE_RECOMMENDATION_CART_QUICK_ADD,
  ANALYTICS_EVENT_CART_QUICK_VIEW,
  ANALYTICS_EVENT_CART_MODAL_ADD,
  ANALYTICS_EVENT_SELECT_CONTENT,
  ANALYTICS_ACTION_VIEW_ITEM
} from '../constants/analytics'

const site = {
  brand_name: BRAND_NAME,
  business_unit: SITE_NAME
}

export const ADD_PRODUCT_DATA = {
  ecommerce: {
    currency: 'USD',
    value: 0,
    items: []
  }
}

export const CART_PRODUCT_DETAIL = {
  ecommerce: {
    currency: 'USD',
    value: 0,
    items: []
  }
}

export const PRODUCT_DETAIL = {
  ecommerce: {
    currency: 'USD',
    value: 0,
    items: []
  }
}

export const PRODUCT_CLICK_DETAIL = {
  ecommerce: {
    currency: 'USD',
    value: 0,
    items: []
  }
}

export const ORDER_CONFIRMATION = {
  ecommerce: {
    transaction_id: '',
    currency: 'USD',
    value: 0,
    items: [],
    event_date: '',
    event_name: '',
    suite_name: ''
  }
}

export const ORDER_CONFIRMATION_PAGE = []

const googleAnalyticsTrackMap = {
  sign_in: {
    ...site,
    event: ANALYTICS_EVENT_PAGE_VIEW,
    page_type: 'login'
  },
  events: {
    ...site,
    event: ANALYTICS_EVENT_PAGE_VIEW,
    click_previous_pv: '',
    page_type: 'my-events'
  },
  order_list: {
    ...site,
    event: ANALYTICS_EVENT_PAGE_VIEW,
    page_type: ANALYTICS_PAGE_TYPE_ORDER_LIST
  },
  create_order: {
    event: ANALYTICS_EVENT_CLICK_CONTENT,
    click_source: '/orders/list',
    click_tracking: ANALYTICS_CLICK_CONTENT_CREATION,
    click_type: ANALYTICS_ACTION_TYPE_CTA,
    link_location: ANALYTICS_ACTION_LOCATION_BODY,
    link_module: 'sub_navigation',
    link_type: ANALYTICS_ACTION_LOCATION_INTERAL
  },
  client_order_creation: {
    event: ANALYTICS_EVENT_CLICK_CONTENT,
    click_source: '/my-events',
    click_tracking: ANALYTICS_CLICK_CONTENT_CREATION,
    click_type: ANALYTICS_ACTION_TYPE_CTA,
    link_location: ANALYTICS_ACTION_LOCATION_BODY,
    link_module: 'order_creation_modal',
    link_type: ANALYTICS_ACTION_LOCATION_INTERAL
  },
  order_creation: {
    event: ANALYTICS_EVENT_CLICK_CONTENT,
    click_source: '/orders/list',
    click_tracking: ANALYTICS_CLICK_CREATION_CONTINUE,
    click_type: ANALYTICS_ACTION_TYPE_CTA,
    link_location: ANALYTICS_ACTION_LOCATION_BODY,
    link_module: ANALYTICS_ACTION_CREATE_ORDER,
    link_type: ANALYTICS_ACTION_LOCATION_INTERAL
  },
  menu: {
    ...site,
    page_type: ANALYTICS_PAGE_TYPE_MENU
  },
  view_cart: { event: ANALYTICS_ACTION_VIEW_CART },
  orders: {
    event: ANALYTICS_EVENT_CLICK_CONTENT,
    click_source: '/orders',
    click_type: ANALYTICS_ACTION_TYPE_CTA,
    link_location: ANALYTICS_ACTION_LOCATION_BODY,
    link_module: 'filter',
    link_type: ANALYTICS_ACTION_LOCATION_INTERAL
  },
  search: {
    event: 'search'
  },
  menu_product_click: {
    event: 'menu_product_click'
  },
  view_item: {
    event: ANALYTICS_ACTION_VIEW_ITEM
  },
  select_item: {
    event: ANALYTICS_EVENT_SELECT_CONTENT
  },
  add_to_cart: {
    event: ANALYTICS_EVENT_CART_MODAL_ADD
  },
  remove_from_cart: {
    event: 'remove_from_cart'
  },
  click_carousel: {
    event: 'click_carousel',
    click_source: ANALYTICS_PAGE_TYPE_ORDER_CART,
    click_tracking: ANALYTICS_EVENT_CAROUSEL_ARROW_CLICK,
    link_location: ANALYTICS_EVENT_CART_QUICK_VIEW,
    link_module: ANALYTICS_ACTION_TYPE_RECOMMENDATION_CART_QUICK_ADD,
    link_type: ANALYTICS_ACTION_LOCATION_INTERAL
  },
  cart_view_checkout: {
    event: ANALYTICS_EVENT_CLICK_CONTENT,
    click_source: ANALYTICS_PAGE_TYPE_ORDER_CART,
    click_tracking: 'menu:cart_view:proceed_to_checkout',
    click_type: ANALYTICS_ACTION_TYPE_CTA,
    link_location: ANALYTICS_ACTION_LOCATION_BODY,
    link_module: ANALYTICS_ACTION_TYPE_CTA,
    link_type: ANALYTICS_ACTION_LOCATION_INTERAL
  },
  checkout_page_view: {
    event: ANALYTICS_EVENT_PAGE_VIEW,
    ...site,
    click_previous_pv: '/orders',
    page_type: 'checkout'
  },
  checkout_page_load: {
    event: 'begin_checkout'
  },
  back_to_ordering: {
    event: ANALYTICS_EVENT_CLICK_CONTENT,
    click_source: 'checkout',
    click_tracking: 'checkout:back_to_ordering',
    click_type: ANALYTICS_ACTION_TYPE_CTA,
    link_location: ANALYTICS_ACTION_LOCATION_BODY,
    link_module: 'checkout_form',
    link_type: ANALYTICS_ACTION_LOCATION_INTERAL
  },
  place_order: {
    event: ANALYTICS_EVENT_CLICK_CONTENT,
    click_source: 'checkout',
    click_tracking: 'checkout:place_order',
    click_type: ANALYTICS_ACTION_TYPE_CTA,
    link_location: ANALYTICS_ACTION_LOCATION_BODY,
    link_module: 'checkout_form',
    link_type: ANALYTICS_ACTION_LOCATION_INTERAL
  },
  order_confirmation: {
    event: ANALYTICS_EVENT_PAGE_VIEW,
    brand_name: BRAND_NAME,
    business_unit: SITE_NAME,
    click_previous_pv: 'order_confirmation',
    page_type: 'confirmation'
  },
  order_confirmation_page_load: {
    event: 'purchase'
  },
  edit_order: {
    event: 'edit_order'
  }
}

export const reportGoogleAnalytics = (
  trackItem,
  details
) => {
  const dataLayer = window.dataLayer || []
  if (googleAnalyticsTrackMap[trackItem] === undefined) { return }
  console.log(trackItem, details)
  dataLayer.push({ ...googleAnalyticsTrackMap[trackItem], ...details } || {})
  console.log(dataLayer)
}
